<div class="header flex flex-col bg-neutral-500 text-white py-4 px-6">
  <h2 class="!m-0">{{ content?.heading }}</h2>
</div>
<div class="py-4 px-6">
  <div class="mb-4">{{ content?.body }}</div>
  <div class="grid gap-4 grid-cols-2">
    <button mat-stroked-button class="large !p-4" (click)="selectSession('RESIDENT')">
      <div><mat-icon color="accent">person</mat-icon></div>
      <div class="!font-semibold !text-lg text-center !m-0">Individual Session</div>
    </button>

    <button mat-stroked-button class="large !p-4" (click)="selectSession('GROUP')">
      <div><mat-icon color="accent">diversity_3</mat-icon></div>
      <div class="!font-semibold !text-lg text-center !m-0">Group Session</div>
    </button>

    <button mat-stroked-button class="large !p-4 col-span-2" (click)="selectSession('ADMIN')">
      <div><mat-icon color="accent">settings</mat-icon></div>
      <div class="!font-semibold !text-lg text-center !m-0">Resident Admin</div>
    </button>


  </div>
</div>
