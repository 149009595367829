
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { svgIcons } from 'src/assets/images/icons/svg/icons';


/**
 * Register our custom SVG within the Mat-Icon library.
 * Doing it dynamically using a manifest file is currently causing loading issues at one facility
 * due to possible firewall restrictions of svg mime type?
 */
export const registerSvgIcons = (matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) => {
  for (const [key, icon] of Object.entries(svgIcons)) {
    matIconRegistry
      .addSvgIconLiteral(key, domSanitizer.bypassSecurityTrustHtml(icon))
  }
}