<div class="breadcrumbs app-container flex flex-row inline-flex">
  <div class="home flex flex-row items-center" routerLink="/">
    <mat-icon>home</mat-icon>
    <div class="arrow-right"></div>
  </div>
  @for (part of _route; track part) {
    <div
      class="crumb flex flex-row items-center"
      [routerLink]="path($index)"
      [ngClass]="{
        disabled: $index === _route.length-1 && $index === routePartCount,
        'level-1': 0 === $index,
        'level-2': 1 === $index,
        'level-3': 2 === $index,
        'level-4': 3 === $index
      }">
      @if ($index > 0) {
        <mat-icon>
          chevron_left
        </mat-icon>
      }
      {{ part | spaceCase | titlecase }}
    </div>
  }
</div>