import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResourcesGuard {
  resourcesDomain = environment.resourcesDomain

  constructor(
    private authService: AuthService,
  ) { }

  canActivate() {
    this.authService.getAuthSession(true).subscribe((session) => {
      const cognitoIdToken = session.tokens.idToken.toString();
      if (cognitoIdToken) {
        window.location.href = this.getResourcesLink(cognitoIdToken);
      }
    });
  }

  getResourcesLink(cognitoIdToken: string) {
    const queryParams = decodeURIComponent(window.location.search.replace(/^\?/, ''));
    return `${this.resourcesDomain}/wp-json/cognito-auth/v1/token?token=${cognitoIdToken}${ queryParams ? '&' + queryParams : ''}`
  }
}
