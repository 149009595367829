<div class="flex flex-row items-center ml-4 mr-4">

  @if (viewMode === 'RESIDENT_LOCK') {
    <button mat-mini-fab [routerLink]="link" class="mr-4">
      <mat-icon>account_circle</mat-icon>
    </button>
    <div class="resident-name text-ellipsis overflow-hidden cursor-pointer" [routerLink]="link">
      {{ currentResidentName }}
    </div>
    <button mat-mini-fab (click)="doExitMode('RESIDENT_LOCK');" class="mr-3" [disabled]="showExitLockModeSpinner">
      @if (!showExitLockModeSpinner) {
        <mat-icon>lock</mat-icon>
      } @else {
        <mat-spinner diameter="16"></mat-spinner>
      }
    </button>
  }
  @if (viewMode === 'RESIDENT_VIEW') {
    <button mat-mini-fab [routerLink]="link" class="mr-4">
      <mat-icon>account_circle</mat-icon>
    </button>
    <div class="resident-name text-ellipsis overflow-hidden cursor-pointer" [routerLink]="link">
      {{ currentResidentName }}
    </div>
    @if (allowLockMode) {
      <button mat-mini-fab (click)="enterLockMode(); showEnterLockModeSpinner = true;" class="mr-3">
        @if (!showEnterLockModeSpinner) {
          <mat-icon>lock_open</mat-icon>
        } @else {
          <mat-spinner diameter="16"></mat-spinner>
        }
      </button>
    }

    <button mat-mini-fab (click)="doExitMode('RESIDENT_VIEW'); showExitResidentSpinner = true;">
      @if (!showExitResidentSpinner) {
        <mat-icon>close</mat-icon>
      } @else {
        <mat-spinner diameter="16"></mat-spinner>
      }
    </button>
  }
</div>