import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { LogoComponent } from '../logo/logo.component';
import { navItems } from './side-nav.config';
import { MatListModule } from '@angular/material/list';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'app-side-nav',
  imports: [LogoComponent, MatIconModule, MatButtonModule, RouterLink, MatListModule],
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Output() toggleSideNav = new EventEmitter();
  @Input() isResponsive: boolean;
  navItems = navItems.filter(val => this.authService.hasGroupAccess(val.groups));

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }
  toggleSidneNav() {
    this.toggleSideNav.emit('toggle');
  }

}
