import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LogoComponent } from '../logo/logo.component';
import { SearchFieldComponent } from '../search-field/search-field.component';
import { environment } from 'src/environments/environment';
import { awsConfig } from '../../../../../../../libs/includes/aws-export';
import { AppStateService } from '../../services/app-state/app-state.service';
import { ViewModes } from '../../models/global';
import dayjs from 'dayjs';


@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [LogoComponent, MatIconModule, MatButtonModule, RouterLink, RouterLinkActive, SearchFieldComponent],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  @ViewChild(SearchFieldComponent) searchField: SearchFieldComponent
  @Input() showItems: {
    residentView: boolean;
    backToAdmin: boolean;
    showLogout: boolean;
    resources: boolean;
    homeIcon: boolean;
  };
  @Output() triggerAdminOptions = new EventEmitter();
  @Output() toggleSideMenu = new EventEmitter<boolean>();
  @Output() exitMode = new EventEmitter<ViewModes>();
  @Output() logout = new EventEmitter<string>();
  @Output() triggerSearch = new EventEmitter<string>();
  @Input() responsive: boolean;
  @Input() facilityId: string;
  enableSearch = environment.enableSearch || localStorage.getItem('enableSearch');
  christmas = dayjs().isBefore(dayjs('2024-12-27'));

  constructor(
    private router: Router,
    private appState: AppStateService
  ) { }

  openAdminOptionDialog() {
    this.triggerAdminOptions.emit(true);
  }

  doToggleSideMenu() {
    this.toggleSideMenu.emit(true);
  }

  doSearch(query?: string) {
    this.triggerSearch.emit(query);
    // reset field for desktop. Mobile doesn't have a searchfield
    if (this.searchField) {
      setTimeout(() => {
        this.searchField.reset();
      })
    }
  }

  backToAdmin(facilityId: string) {
    let url = `${awsConfig.adminAppUrl}/facilities/${facilityId}/residents/list`;
    // For local testing purposes only
    if (!environment.production && ['localhost', environment.local.domain].includes(window.location.hostname)) {
      url = url.replace(/https:\/\/[^/]*/, `http://${environment.localAdminApp.domain}:${environment.localAdminApp.port}`);
    }
    window.location.href = url;
  }

  backToResidentView() {
    const mode = this.appState.get<ViewModes>('viewMode');
    if (mode === ViewModes.GROUP) {
      this.exitMode.emit(ViewModes.GROUP);
    } else if (mode === ViewModes.ADMIN_MODE) {
      this.exitMode.emit(ViewModes.ADMIN_MODE);
    } else {
      this.exitMode.emit(ViewModes.RESIDENT_VIEW);
    }
  }

  // Not sure if this function should be here
  clearFacilityId() {
    window.sessionStorage.removeItem('APOLLO_FACILITY_ID');
  }

  doLogout(mode: string) {
    this.logout.emit(mode);
  }
}
