import { Component } from '@angular/core';
import { WILSelectSessionComponent } from '../../components/select-session/select-session.component';
import { WILSelectResidentComponent } from '../../components/select-resident/select-resident.component';

import { WILSelectGroupComponent } from '../../components/select-group/select-group.component';
import { WhoIsListeningService } from '../../services/who-is-listening.service';
import { Router } from '@angular/router';
import { ViewModes } from 'src/app/shared/models/global';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Resident } from '@resparke/models/resident.model';


@Component({
  selector: 'app-wil-session',
  imports: [WILSelectSessionComponent, WILSelectResidentComponent, WILSelectGroupComponent],
  templateUrl: './session.component.html',
  styleUrl: './session.component.scss'
})
export class WILSessionComponent {
  selectSession = true;
  selectResident = false;
  selectGroup = false;

  constructor(
    private whoIsListening: WhoIsListeningService,
    private router: Router,
    private appState: AppStateService,
  ) {
  }

  startSession(type: 'RESIDENT' | 'GROUP' | 'ADMIN') {
    this.selectSession = false;
    if (type === 'RESIDENT') {
      this.selectResident = true
    }
    if (type === 'GROUP') {
      this.selectGroup = true;
    }
    if (type === 'ADMIN') {
      this.startAdminMode();
    }
  }

  goBack() {
    this.selectResident = false;
    this.selectGroup = false;
    this.selectSession = true;
  }


  startAdminMode() {
    this.router.navigate(['manage/residents/list']);
    this.appState.startMode(ViewModes.ADMIN_MODE);
  }

  startResidentMode(resident: Resident) {
    if (resident.id === 'anonymous') {
      this.router.navigate(['/']);
      this.appState.startMode(ViewModes.ANONYMOUS);
      this.whoIsListening.startSession('ANONYMOUS');
    } else if (resident.id === 'add-new') {
      this.router.navigate(['manage/residents/add']);
      this.appState.startMode(ViewModes.ADMIN_MODE);
    } else {
      this.router.navigate(['/resident', resident.id, 'profile']).finally(() => {
        this.appState.startMode(ViewModes.RESIDENT_VIEW, resident.id);
        this.whoIsListening.startSession('RESIDENT');
      });
    }
  }

  startGroupMode(group: 'SMALL' | 'MEDIUM' | 'LARGE' | 'MEAL_TIME') {
    this.router.navigate(['/']).finally(() => {
      this.appState.startMode(ViewModes.GROUP, group);
      this.whoIsListening.startSession('GROUP');
    });
  }
}
