@if (viewMode$ | async; as viewMode) {

  <mat-sidenav-container (backdropClick)="leftSidenav.close()">
    <mat-sidenav #leftSidenav [opened]="layout.opened" [mode]="layout.mode" disableClose autoFocus="false">
      <app-side-nav
        class="flex flex-col basis-full"
        (toggleSideNav)="layout.responsive && leftSidenav.toggle()"
        [isResponsive]="layout.responsive"
        ></app-side-nav>
    </mat-sidenav>

    <mat-sidenav-content
      class="flex flex-col"
      [ngClass]="{'is-responsive': layout.responsive,
        'has-breadcrumbs': layout.breadCrumbs,
        'resident-view-mode': viewMode === 'RESIDENT_VIEW',
        'resident-lock-mode': viewMode === 'RESIDENT_LOCK'
      }">

      <header role="page-header">
        @if (viewMode === 'RESIDENT_VIEW' || viewMode === 'RESIDENT_LOCK') {
          <app-resident-mode-header
            [isMobile]="isMobile"
            [viewMode]="viewMode"
            [currentResidentName]="currentResidentName"
            [link]="['resident', currentResidentId, 'profile']"
            [ngClass]="{'resident-lock': viewMode === 'RESIDENT_LOCK', 'resident-view': viewMode === 'RESIDENT_VIEW' }"
            (exitMode)="exitViewMode($event)"
            (lockMode)="enterLockMode()"
            [allowLockMode]="allowLockMode"
            [showExitLockModeSpinner]="showExitLockModeSpinner"
            class="flex flex-col place-content-center">
          </app-resident-mode-header>
        }

        <app-nav-bar class="mat-elevation-z3"
          [showItems]="showNavItems"
          (toggleSideMenu)="leftSidenav.toggle()"
          [facilityId]="facilityId"
          [resourcesLink]="resourcesLink"
          (logout)="doLogout($event)"
          (exitMode)="exitViewMode($event)"
          (triggerSearch)="doSearch($event)"
          [christmas]="christmas"
          [responsive]="layout.responsive">
        </app-nav-bar>

        @if (layout.breadCrumbs) {
          <app-bread-crumb [route]="currentRoute"
            [kind]="layout.breadCrumbKind"></app-bread-crumb>
        }
      </header>
      <router-outlet></router-outlet>
    </mat-sidenav-content>

    @if (enableWhoIsListening && toggleWhoIsListeningDrawer$ | async; as toggleWhoIsListeningDrawer) {
      <mat-sidenav
        #rightSidenav
        [opened]="toggleWhoIsListeningDrawer"
        mode="over"
        disableClose
        autoFocus="false"
        position="end"
        class="sm:!w-[30rem] !w-[90%]">
        @if (toggleWhoIsListeningDrawer) {
          <app-wil-session class="flex flex-col basis-full"></app-wil-session>
        }
      </mat-sidenav>
    }
  </mat-sidenav-container>
  <router-outlet name="v"></router-outlet>
}

