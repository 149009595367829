import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { HomePageService } from '../services/home.service';
import { CalendarEvent } from '../models/events.model';


@Injectable({
  providedIn: 'root'
})
export class CalendarEventsResolver  {
  constructor(
    private homepageService: HomePageService,
    private appState: AppStateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<null> {
    const events = this.appState.get<CalendarEvent[]>('calendarEvents') || [];

    if (!events.length) {
      // we are not interested in returning anything to router since we use appState
      return this.homepageService.getThisWeeksEvents()
        .pipe(map<number[], any>(val => null));
    } else {
      return of(null);
    }
  }
}
