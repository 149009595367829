import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { HomePageService } from '../services/home.service';
import { HomePageBanner } from '../models/events.model';

@Injectable({
  providedIn: 'root'
})
export class HomeBannersResolver  {
  constructor(
    private homepageService: HomePageService,
    private appState: AppStateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HomePageBanner[]> {
    const banners = this.appState.get<HomePageBanner[]>('homePageBanners') || [];
    if (!banners.length) {
      return this.homepageService.getHomePageBanners();
    } else {
      return of(banners);
    }
  }
}
