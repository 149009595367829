import { Component, HostBinding, Input } from '@angular/core';
import { NgClass, TitleCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { SpaceCasePipe } from '../../pipes/space-case/space-case.pipe';
import { BreadcrumbKind } from '../authenticated-layout/authenticated-layout.component';

@Component({
  selector: 'app-bread-crumb',
  imports: [MatIconModule, RouterLink, TitleCasePipe, SpaceCasePipe, NgClass],
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent {
  className: string;
  _route: string[];
  routePartCount: number;
  @Input()
  set route(route: string[]) {
    this.routePartCount = route.length-1;
    this._route = route.filter(val => val.search(/(video|music)\:/i) !== 0);
  }

  @HostBinding('class') @Input('kind') classList: BreadcrumbKind;

  path(index: number) {
    const parts = window.location.pathname.replace(/^\//,'').split('/');
    const url = parts.slice(0, index+1).join('/');
    return url;
  }
}
