<div class="flex flex-col basis-full">
  <div class="topbar-container flex flex-row items-center place-content-between">
    <app-logo width="127" height="31"></app-logo>
    @if (isResponsive) {
      <button mat-icon-button (click)="toggleSidneNav()">
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>

  <mat-list role="list" >
    @for (item of navItems; track item.path) {
      <mat-list-item role="listitem" color="primary" [routerLink]="item.path" (click)="toggleSidneNav()">
        <mat-icon matListItemIcon [svgIcon]="item.svgIcon">{{item.icon}}</mat-icon>
        <div matListItemTitle>{{item.title}}</div>
      </mat-list-item>
    }
  </mat-list>

</div>