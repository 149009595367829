<div class="header flex flex-col bg-neutral-500 text-white py-4 px-6">
  <h2 class="!m-0 flex flex-row items-center">
    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back_ios</mat-icon>
    {{ content?.heading }}</h2>
</div>
<div class="flex flex-col basis-full">

  @if (residents$ | async) {

    <div class="search flex flex-col">
      <mat-form-field class="search" subscriptSizing="dynamic">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search for a resident" [formControl]="search" />
      </mat-form-field>
    </div>

    <div class="table-wrapper flex flex-col basis-full">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            @if (element.id === 'add-new') {
              <mat-icon color="accent">add_circle</mat-icon>
            } @else {
              @if (!element.startResidentView) {
                <mat-icon color="accent">switch_account</mat-icon>
              }
              @if (element.startResidentView) {
                <div class="action-spinner flex place-content-center">
                  <mat-spinner diameter="16"></mat-spinner>
                </div>
              }
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
          <td mat-cell *matCellDef="let element" width="90%">
              {{ element.firstName }} {{ element.lastName }}
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="cursor-pointer hover:bg-slate-100" (click)="handleClick(element)"></tr>
      </table>
    </div>
  } @else {
    <div class="flex flex-col items-center justify-center basis-full">
      <div class="flex flex-row items-center gap-2">
        <mat-spinner diameter="20"></mat-spinner>
        Loading residents
      </div>
    </div>
  }
</div>